import headerimg from "./images/homew.jpg";

function Pageheader(props){
    return(
        
        <section className="header-overlay mt-5" style={{ backgroundImage: `url(${headerimg})` }}>
   
        <div className="overlay-txt text-center ">
            <div className="container-fluid ">
            <h1 style={{}}>{props.title}</h1>
            </div>
        </div>
        </section>


    );
}
export default Pageheader;