import { Link } from 'react-router-dom';
import q1 from './images/q1.jpg';
import q2 from './images/q2.jpg';
import q3 from './images/q3.jpg';



function Aboutdata(){
    return(

        <section className="about-us sec-add">
        <div className="container ">
            <div className="row ">
                <div className="col-12 col-md-3 col-lg-7">
                  <div className="row  g-4">
  
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="row g-4">
                            
                            <img src={q2} class="abt-img img-fluid" height="150px"  alt=""/>
                            <img src={q1}  class="abt-img " height="190px"   alt=""/>
                        </div>
                      </div>
                      <div className="col">
                          <img src={q3} class="abt-img" id="img3" width="300px" alt="" srcset=""/>
                      </div>
                  </div>
  
  
                </div>
                <div className="col-12 col-md-3 col-lg-5">
                    
                      <div className="about-txt text-start  text-dark sec-add">
                          <h2 className="fw-bold"Renewable Energy is The Future>Renewable Energy is The Future</h2>
                          <p>We are happy to introduce our services to build a better future. Join hands
                              for renewable energy sustainable development
                          </p>
                          <span><Link className="btn"  to="/services">Our Services</Link></span>
                      </div>
                      
  
                </div>
            </div>
        </div>
      
      </section> 




    );
}
export default Aboutdata;