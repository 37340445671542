import ongrid from './images/on-grid-solar.jpg';
import offgrid from './images/off-grid-solar.jpg';




function Servicedata(){
    return(

<section className="service-card mt-5 ">
    <div className="container" >
       <h2 className="text-center fw-bold mb-5 ">Service We Offer</h2>
        <div className="row">
            
                 <div className="col-12 col-md-4 col-lg-6">
                        <div className="card text-center mb-4 card-all ">
                     <img src={ongrid} height="350px" width="250px" class="card-img-top"  alt=""/>
                     <div className="card-body">
                        <h5 className="card-title pt-3 ">On grid solar</h5>
                     </div>
                     
                    
                    </div>
                    </div>
            
                             <div className="col-12 col-md-4 col-lg-6">
                        <div className="card text-center mb-4 card-all">
                     <img src={offgrid} height="350px" width="250px" class="card-img-top"  alt=""/>
                     <div className="card-body">
                        <h5 className="card-title pt-3 ">Off grid solar</h5>
                     </div>
                     
                    </div>
                    </div>
                    </div>
                    </div>
</section>


    );
}
export default Servicedata;