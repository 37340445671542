import Trcard from "./Tr-card";
import solartech from "./images/solar-tech.jpg";
import invertertech from "./images/inver-tech.jpg";
import evtech from "./images/evtech.jpg";
import eaudit from "./images/e-audit.jpg";





function Training(){
    return(
   
    <section className="training sec-add">
        <div className="container">
            <h2 className="text-start fw-bold mb-5 ">Training</h2>
            <div class="row ">

                <Trcard  title="Solar Technician" img = {solartech}/>
                <Trcard  title="Inverter Technician" img = {invertertech}/>
                <Trcard  title="E V Technician" img = {evtech}/>
                <Trcard  title="Energy Audit Assistant" img = {eaudit}/>
   

           </div>
         </div>
    </section>
    );
}
export default Training;