import Pageheader from "../components/Pageheader";
import Footer from "../components/Footer";
import Training from "../components/Training";
import Products from "../components/Products";
import Cta from "../components/Cta";
import Servicedata from "../components/Servicedata";

function Services() {
  return (
    <div className="App">
      
      <Pageheader title = "Services"/>
      <Servicedata />
      <Training />
      <Products />
      <Cta />
      <Footer />
    </div>
  );
}

export default Services;
