const Trcard = (props) =>{

    return(
  
    <div className="col-12 col-md-3 col-lg-3">
        <div className="card text-center mb-4 card-all">
           <img src={props.img} className="card-img-top" alt=""/>
            <div className="card-body">
              <h5 className="card-title ">{props.title}</h5>
            </div>   
        </div>
    </div>
  
  
    );
  
  }
  export default Trcard;