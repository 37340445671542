import Logo from  './images/logo.png';
import phonei from '../components/images/phonei.png';
import locationi from '../components/images/mark.png';

const Footer = ()  => {
 return(
     
    
<footer>
    <section className="footer-area py-5 ">
        <div className="container text-start text-white">
            <div className="row gy-4">
                <div className="col-12 col-md-4 col-lg-4">
                <img src={Logo} width="140px"  alt="logo"/>
    
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <h5 className='text-dark'><img src={locationi} width="25px"  alt="" srcset=""/>&nbsp;Address</h5>
                   <p  className='text-dark'>
                   B 12, Mavelipuram, P.O.Kakkanad,<br/>

                  Cochin 682030</p> 
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <h5 className='text-dark'> <img src={phonei} width="25px" alt="" srcset=""/>&nbsp;Contact us</h5>
                   <p className='text-dark'>
                   +91 8129336783<br/>
                    +91 9895655783<br/>
                    infoenergia.kerala@gmail.com
                    </p> 


                </div>
            </div>
        </div>
    </section>
</footer>





 );
}
export default Footer;