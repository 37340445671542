function Gmap( props){

   

    return(

      <div className="container-fluid m-0 p-0">          
        <iframe src={props.src} height={props.height} width={props.width} title={props.title}>   </iframe>      
      </div>
        


    );
}
export default Gmap;