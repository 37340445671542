import Header from "../components/Header";
import Footer from "../components/Footer";
import Aboutdata from "../components/Aboutdata";
import Training from "../components/Training";
import Products from "../components/Products";


function Home() {
  return (
    <div className="App">
      <Header />
      <Aboutdata />
      <Training />
      <Products />
      
      <Footer />
    </div>
  );
}

export default Home;
