import { Link } from 'react-router-dom';
import Ctaimg from "./images/home.jpg";

function Cta(){
    return(
        <section className="cta" style={{backgroundImage : `url(${Ctaimg})`  }}>
    
        <div className="overlay-txt text-center ">
            <div className="container-fluid text-center">
            <h1>Like what we offer? reach us today!</h1>
            <span><Link className="btn"  to="/contact">Contact us</Link></span>
                     
            </div>
    </div>
</section>

    );
}
export default Cta;