

const Prcard = (props) =>{

  return(

     <div className=" col-md-4 col-lg-4">
          <div className="card text-center mb-4 card-all">
               <img src={props.img} className="card-img-top" alt=""/>
                  <div className="card-body">
                     <h5 className="card-title ">{props.title}</h5>
                   </div>   
           </div>
     </div>




  );

}
export default Prcard;