import Prcard from "./Pr-card";
import inverterimg from "./images/inverter.jpg"
import solarpanel from  "./images/solar.jpg";
import solarcontrol from "./images/Solar-Charge-Controllers.jpg";
import bike from '../components/images/ele-bike.jpg';

function Products(){
    return(
   
    <section className="products sec-add pb-5">
        <div className="container">
            <h2 className="text-start fw-bold mb-5 ">Products</h2>
            <div className="row ">

                <Prcard  title = "Solar Inverter" img ={inverterimg}/>
                <Prcard  title = " Solar Panels" img = {solarpanel} />
                <Prcard  title = "Solar Charge Controllers " img = {solarcontrol}/>
                
   

           </div>
         </div>
        <div className="container">
            {/*  */}
        <div className="container">
            <img src={bike}  alt="data" className="new-pic"/>
        </div>
        </div>
    </section>
    );
}
export default Products;