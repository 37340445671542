

const Homecard = (props) =>{
    return(
     <>
        <div className="col-12 col-lg-4 col-lg-4">
         <div className="card p-4 rounded home-card mb-4 ">
             <span className="mb-4"><img src={props.img} width="80px"  alt=""/></span>
             <h5 className="text-dark fw-bold">{props.title}</h5>
             <p>{props.txt}</p>
         </div>
        </div>
        
 
     </>
    );
}
export default Homecard;