import Homecard from './Homecard';
import bgimg from './images/homew.jpg';
import icon1 from './images/1.png';
import icon2 from './images/2.png';
import icon3 from './images/3.png';

function Header(){
    return(
      <>
        <header>
        <section className="hero d-flex align-items-center   mt-5" style={{backgroundImage: `url(${bgimg})` }} >
 
           <div className="container-fluid">
               
               <div className="hero-txt text-center   ">
                 <div className="col-12 ">
                 <h1 className="text-white fw-bold pb-3">
                  "Renewable Energy is The Future"
                 </h1>
                 <span><a className="btn" href='/services'><b>Know more</b></a></span>
               </div>
             </div>
           </div>
        </section>
    </header>
    
    
    
     <section className="icon-cards mb-5">
        <div className="container">
           <div className="row ">
     
              <Homecard  img ={icon1} title= "Harvest Green Energy"/>
              <Homecard  img ={icon3} title= "Store Green Energy"/>
              <Homecard  img ={icon2} title= "Reduce  electric bill"/>
              


            </div>
          </div>
      </section>
      
    </>
    );
}
export default Header;