import phonei from '../components/images/phonei.png';
import locationi from '../components/images/mark.png';
import Pageheader from '../components/Pageheader';
import Footer from '../components/Footer';
import Gmap from '../components/Gmap';


function Contact(){
    return(
        <>
         <Pageheader title = "Contact Us"/>
         {/*--------------------------------------  */}
        <section class="contact-details pt-5 pb-5">
    <div class="container text-center ">
        <div class="row g-3">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card text-center card-all p-3 d-flex align-items-center ">
                    <img src={phonei} width="40px" alt="" srcset=""/>
                    <div class="card-body">
                        <h4 class="card-title">Contact us</h4>
                        <p class="card-text fw-bold">+91 8129336783<br/>
                            +91 9895655783
                            <br/>
                            infoenergia.kerala@gmail.com
                            </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card text-center card-all p-3 d-flex align-items-center">
                    <img src={locationi} width="40px" alt="" srcset=""/>
                    <div class="card-body">
                        <h4 class="card-title">Location</h4>
                        <p class="card-text fw-bold">B 12, Mavelipuram, P.O.Kakkanad<br/>
                            Cochin 682030</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  

{/* map component  */}
<Gmap  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15715.808309609727!2d76.3420069!3d10.0208135!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x516a97ea4c3f8baa!2sJAINx%20-%20The%20Learning%20Hub!5e0!3m2!1sen!2sin!4v1647250642051!5m2!1sen!2sin"  width="100%"  height="450"/>

<Footer />
</>
    );
}
export default Contact;