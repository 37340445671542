
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Services from "./pages/Services";



function App() {
  return (
    <div className="App">
    
     <Navbar />
    
      <Routes>
        <Route path="/"  element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
   
     
      </div>
  );
}

export default App;
